import * as React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as styles from "./privacy.module.css"

const PrivacyPage = () => {
    return (
        <Layout isTop={false}>
            <Seo title="プライバシーポリシー"
                 description="nullhouseのプライバシーポリシーはこちらでご覧いただけます。"/>
            <div className={styles.content}>
                <h2>プライバシーポリシー</h2>
                <p>このプライバシーポリシー（以下、「本ポリシー」といいます）は、nullhouse合同会社（以下、「当社」といいます）が提供するスマートフォン用アプリケーション（以下、「当社アプリ」といいます）および当社アプリに関するサービス（以下、「本サービス」といいます）における利用者情報の取扱いを定めたものです。</p>

                <h3 className={styles.sectionTitle}>1. 取得情報および利用目的</h3>
                <ol>
                    <li>端末情報<br/>当社は、利用に関する動向調査のため、利用者の端末を識別するIDやOSのバージョンなどの端末に関する情報を収集することがあります。</li>
                    <li>ログ情報及び行動履歴情報<br/>当社は、サービスの維持及び改善、又は不正行為防止のため、サービスの利用時に自動で生成、保存されるIPアドレス、ユーザーからのリクエスト日時、アプリ内での操作履歴の情報を収集することがあります。
                    </li>
                </ol>

                <h3 className={styles.sectionTitle}>2. 情報収集モジュール</h3>
                <p>当社アプリおよび本サービスの提供や利用に関する動向調査、利用者に適切な広告表示をするために、当社アプリおよび本サービスの機能とは独立した、第三者による情報収集モジュールを利用することがあります。</p>
                <ol>
                    <li>Firebase Analytics（Google Inc.）<br/>利用に関する動向調査のため</li>
                    <li>AdMob（Google Inc.）<br/>利用者に適切な広告表示をするため</li>
                    <li>Crashlytics（Google Inc.）<br/>アプリケーションのクラッシュ情報を調査するため</li>
                </ol>

                <h3 className={styles.sectionTitle}>3. 情報の開示・提供</h3>
                <p>当社は、当社アプリ、及び、本サービスにて利用者から取得、保存した個人情報を、次のいずれかに該当する場合を除き、本ポリシーに明示している第三者以外に開示または提供することはありません。</p>
                <ol>
                    <li>利用者の同意がある場合</li>
                    <li>法令に基づき開示することが必要である場合</li>
                    <li>合併、会社分割、事業譲渡その他の事由によりユーザーの個人情報を含む事業の承継がなされる場合</li>
                    <li>その他、社会通念上、当社が必要と判断した場合であって、本人の同意を得ることが困難である場合</li>
                </ol>

                <h3 className={styles.sectionTitle}>4. 利用者情報の送信停止および削除</h3>
                <p>利用者情報の自動送信を停止する手段を提供しておりません。利用者情報の自動送信を停止したい場合は、当社アプリをアンインストールしてください。</p>

                <h3 className={styles.sectionTitle}>5. 法令、規範の遵守と見直し</h3>
                <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</p>

                <h3 className={styles.sectionTitle}>6. 改定</h3>
                <p>当社は、本ポリシーの全部または一部を改定することがあります。</p>

                <h3 className={styles.sectionTitle}>7. お問い合せ</h3>
                <p>当社アプリおよび本サービスにおける利用者情報の取扱いに関するお問い合せは、下記までご連絡ください。</p>
                <p>nullhouse0@gmail.com</p>

                <div className={styles.date}>2017年8月26日改定</div>
            </div>
        </Layout>
    )
}

export default PrivacyPage
